import {initializeApp} from 'firebase/app'
import {getAuth} from 'firebase/auth';
import {Firestore, initializeFirestore} from 'firebase/firestore';
import {getFunctions} from 'firebase/functions';
import {getAnalytics} from 'firebase/analytics';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
}

console.log("firebaseConfig:", firebaseConfig)

const firebaseApp = initializeApp(firebaseConfig)
export const analytics = getAnalytics(firebaseApp)
export const auth = getAuth(firebaseApp)
export const firestore: Firestore = initializeFirestore(firebaseApp, { experimentalForceLongPolling: true })
export const functions = getFunctions(firebaseApp)
