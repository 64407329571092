import React from 'react'
import { SvgIcon } from '@material-ui/core'

function GoogleCalendarIcon() {
  return (
    <SvgIcon width={24} height={24}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 48 48"
        width="24"
        height="24"
      >
        <g id="Layer_3" fillRule="evenodd" clipRule="evenodd">
          <path
            d="M38,5c-6.302,0-21.698,0-28,0C8.895,5,8,5.895,8,7 c0,3.047,0,3,0,3h32c0,0,0,0.047,0-3C40,5.895,39.105,5,38,5z M14,8c-0.552,0-1-0.448-1-1c0-0.552,0.448-1,1-1s1,0.448,1,1 C15,7.552,14.552,8,14,8z M34,8c-0.552,0-1-0.448-1-1c0-0.552,0.448-1,1-1s1,0.448,1,1C35,7.552,34.552,8,34,8z"
            fill="#c7c7c7"
          />
          <path
            d="M44,11c0.103-0.582-1.409-2-2-2C34.889,9,13.111,9,6,9 c-1,0-2.103,1.418-2,2c0.823,4.664,3,15,3,15h34C41,26,43.177,15.664,44,11z"
            fill="#1976d2"
          />
          <path
            d="M41,26H7c0,0-2.177,10.336-3,15c0,1.146,0.792,2,2,2 c7.111,0,28.889,0,36,0c0.591,0,2-0.5,2-2C43.177,36.336,41,26,41,26z"
            fill="#1e88e5"
          />
          <path
            d="M20.534,26c0.984,0.325,1.687,0.85,2.105,1.557 c0.433,0.732,0.65,1.55,0.65,2.457c0,1.582-0.519,2.826-1.556,3.733c-1.037,0.906-2.363,1.36-3.977,1.36 c-1.582,0-2.892-0.427-3.93-1.282c-1.038-0.855-1.536-2.014-1.497-3.476l0.036-0.072h2.242c0,0.914,0.28,1.642,0.841,2.182 c0.56,0.541,1.33,0.811,2.308,0.811c0.994,0,1.773-0.27,2.337-0.811c0.564-0.541,0.847-1.34,0.847-2.397 c0-1.073-0.25-1.864-0.751-2.373c-0.501-0.509-1.292-0.763-2.373-0.763h-2.051V26H20.534z"
            fill="#fafafa"
          />
          <rect x="31.637" y="26" width="2.349" height="8.856" fill="#fafafa" />
          <path
            d="M14.727,22.036h-2.254l-0.024-0.072 c-0.04-1.312,0.435-2.427,1.425-3.345c0.99-0.918,2.284-1.377,3.882-1.377c1.606,0,2.886,0.427,3.84,1.282 c0.954,0.855,1.431,2.073,1.431,3.655c0,0.716-0.217,1.429-0.65,2.141c-0.433,0.712-1.083,1.254-1.95,1.628L20.534,26h-4.77v-0.911 h2.051c1.042,0,1.779-0.26,2.212-0.781c0.433-0.521,0.65-1.246,0.65-2.176c0-0.994-0.246-1.749-0.739-2.266 c-0.493-0.517-1.22-0.775-2.182-0.775c-0.914,0-1.648,0.268-2.2,0.805C15.022,20.414,14.746,21.098,14.727,22.036z"
            fill="#e0e0e0"
          />
          <polygon
            points="33.986,26 31.637,26 31.637,19.782 28.083,19.83 28.083,18.136 33.986,17.492"
            fill="#e0e0e0"
          />
          <path
            d="M6,9c-1.438,0-2.103,1.418-2,2c0.823,4.664,3,15,3,15"
            fill="#1976d2"
          />
          <path
            d="M41,26c0,0,2.177-10.336,3-15c0-1.625-1.409-2-2-2"
            fill="#1976d2"
          />
        </g>
      </svg>
    </SvgIcon>
  )
}

export default GoogleCalendarIcon
