import React from 'react'
import { SvgIcon } from '@material-ui/core'

function MacOSIcon() {
  return (
    <SvgIcon width={24} height={24}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 48 48"
        width="24"
        height="24"
      >
        <g id="surface1">
          <path
            d="M 40.085938 32.613281 C 39.234375 34.449219 38.828125 35.269531 37.742188 36.886719 C 36.222656 39.152344 34.070313 41.976563 31.414063 41.996094 C 29.054688 42.015625 28.445313 40.488281 25.238281 40.515625 C 22.035156 40.53125 21.367188 42.023438 19.003906 41.996094 C 16.347656 41.976563 14.316406 39.429688 12.792969 37.171875 C 8.535156 30.832031 8.085938 23.402344 10.71875 19.449219 C 12.578125 16.648438 15.523438 15.003906 18.289063 15.003906 C 21.105469 15.003906 22.878906 16.515625 25.203125 16.515625 C 27.46875 16.515625 28.84375 15 32.101563 15 C 34.566406 15 37.171875 16.3125 39.03125 18.574219 C 32.941406 21.835938 33.929688 30.335938 40.085938 32.613281 Z"
            fill="#42a5f5"
          />
          <path
            d="M 30.046875 12.070313 C 31.316406 10.496094 32.277344 8.269531 31.929688 6 C 29.859375 6.136719 27.4375 7.417969 26.023438 9.074219 C 24.742188 10.585938 23.679688 12.828125 24.09375 14.996094 C 26.351563 15.066406 28.6875 13.765625 30.046875 12.070313 Z"
            fill="#42a5f5"
          />
          <path
            d="M 36.734375 20.421875 C 28 30 20 21 9.226563 27.84375 C 9.601563 30.867188 10.757813 34.144531 12.792969 37.171875 C 14.3125 39.429688 16.347656 41.976563 19.003906 42 C 21.367188 42.023438 22.035156 40.53125 25.238281 40.515625 C 28.445313 40.492188 29.054688 42.015625 31.414063 41.996094 C 34.070313 41.976563 36.222656 39.152344 37.742188 36.886719 C 38.828125 35.269531 39.234375 34.449219 40.085938 32.613281 C 34.878906 30.6875 33.390625 24.3125 36.734375 20.421875 Z"
            fill="#1e88e5"
          />
        </g>
      </svg>
    </SvgIcon>
  )
}

export default MacOSIcon
