export const UID = 'uid'
export const ADDRESS1 = 'address1'
export const ADDRESS2 = 'address2'
export const CITY = 'city'
export const ZIP = 'zip'
export const STATE = 'state'
export const DISPLAY_NAME = 'displayName'
export const EMAIL = 'email'
export const PASSWORD = 'password'
export const PHONE = 'phone'
export const PHOTO_URL = 'photoURL'
export const DATE_OF_BIRTH = 'dateOfBirth'
export const GENDER = 'gender'
export const CREATED_AT = 'createdAt'
export const EMAIL_VERIFIED = 'emailVerified'
export const MEMBERSHIP_EXPIRES_AT = 'membershipExpiresAt'
export const NOT_INTERESTED_IN_BECOMING_A_MEMBER =
  'notInterestedInBecomingAMember'
export const GRAVATAR_URL = 'gravatarUrl'

export const SUBSCRIBERS_ARRAY_KEY = 'values'
export const IS_MEMBER = 'isMember'

export const ONLY_ME = 'ONLY_ME'
export const MEMBERS = 'MEMBERS'
